.eck-photo-grid {
  display: grid;
  background-color: white;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Passe die Breite der Bilder nach Bedarf an */
  grid-auto-rows: 1fr;
  grid-gap: 10px;
  grid-row-gap: 10px;
  max-width: 1200px;
  grid-auto-rows: 200px;
  /* grid-auto-flow: dense; */
  margin: 0 auto;
}

.eck-photo-item {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.eck-photo-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.h-stretch {
  grid-column: span 2;
}
.v-stretch {
  grid-row: span 2;
}
.big-stretch {
  grid-row: span 2;
  grid-column: span 2;
}

@media (max-width: 768px) {
  .eck-photo-grid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); /* Passe die Breite der Bilder nach Bedarf an */
    grid-auto-rows: 100px;
  }
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  /* position: relative; */
  display: flex; /* Damit das Bild im Container die volle Höhe einnehmen kann */
  justify-content: center; /* Zentrieren des Bilds vertikal */
  align-items: center; /* Zentrieren des Bilds horizontal */
  height: 100vh; /* 100% der verfügbaren Bildschirmhöhe */
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
  transform: translate(0, 0);
}

.modal-content.hidden {
  opacity: 0;
  transform: translate(0, -500px); /* Ändern Sie den Wert entsprechend Ihrer Anforderungen */
}

.modal-content img {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: 0 auto;
}

/* Stil für das Schließen-Icon im Modal, optional */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #fff;
  font-size: 24px;
}

/* Pfeile für die Bildnavigation im Modal */
.modal-arrow {
  position: absolute;
  font-size: 3vh;
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
  color: #333;
  cursor: pointer;
  padding: 3vh;
  outline: none;
  transition: background-color 0.3s ease;
}

.modal-arrow:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

/* Pfeil links */
.modal-arrow-left {
  right: 10px;
  bottom: 22vh;
}

/* Pfeil rechts */
.modal-arrow-right {
  right: 10px;
  bottom: 10vh;
}
