/* .startseite {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  background-image: url("/startseite_hintergrund.png");
  background-size: cover;
  background-position: center;
  min-height: 90vh;
  margin: 0;
  padding: 0;
}

.startseite__content {
  margin: 1rem;
  width: calc(45% - 2rem);
  min-width: 17.5rem;
  padding: 0;
} */
.startseite {
  /* background-image: url("/startseite_hintergrund.png"); */
  background-color: darkgreen;
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.startseite-content {
  background-color: rgba(255, 255, 255, 0.5); /* halbtransparente Hintergrundfarbe */
  padding: 20px;
  text-align: center;
}

.startseite h1 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.startseite h2 {
  font-size: 1rem;
  margin-bottom: 10px;
}

.startseite h3 {
  font-size: 0.75rem;
}
