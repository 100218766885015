.subject-item {
  margin: 1rem;
  width: calc(45% - 2rem);
  min-width: 17.5rem;
}

.subject-item a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  padding: 0.5rem;
  color: white;
}

.subject-item__header {
  background: #292929;
}
.subject-item__header:hover {
  background: #ffd900;
}

.subject-item__content {
  padding: 0;
}

.subject-item__image {
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
}

.subject-item__info {
  background: #ffd900;
  color: #292929;
}
.subject-item__info:hover {
  background: #292929;
  color: #ffd900;
}

.subject-item__info h2 {
  font-size: 1.5rem;
  margin: 0;
  padding-left: 10px;
  font-weight: normal;
}
.subject-item__info h3 {
  margin: 0;
  padding-left: 15px;
}
.subject-item__info h4 {
  padding-left: 15px;
  margin: 0;
}
.subject-item:hover h4,
.subject-item:active h4 {
  color: #ffd900;
  cursor: pointer;
}
