.card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.card-content {
  background-color: #fff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 20px;
  width: 60%;
  margin: auto; /* horizontally center the card */
  margin-top: 20px; /* add a top margin for vertical centering */
}

.card-avatar {
  margin-right: 20px;
  width: 160px;
}

.card-texte {
  flex: 1;
}

.card-texte h2,
.card-texte h3 {
  margin: 0;
}

.card-texte h2 {
  font-size: 20px;
  font-weight: bold;
}

.card-texte h3 {
  font-size: 16px;
}

button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
  width: fit-content;
}
