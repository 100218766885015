.thumbnails-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.thumbnails-container a {
  margin: 10px;
}

.thumb {
  width: 100%;
  max-width: 350px;
}
