.pic-item {
  margin: 1rem 0;
}

.pic-item__title {
  padding: 5px;
  font-size: 2em;
}

.pic-item__description {
  padding: 5px;
  font-size: 1em;
}

.pic-item__description button {
  padding: 0px;
}

.pic-item__info {
  padding: 1rem;
  text-align: center;
}

.pic-item__info h2,
.pic-item__info h3,
.pic-item__info p {
  margin: 0 0 0.5rem 0;
}

.pic-item__actions {
  padding: 1rem;
  text-align: center;
  border-top: 1px solid #ccc;
}

.pic-item__modal-content {
  padding: 0;
}

.pic-item__modal-actions {
  text-align: right;
}

.pic-item__actions button,
.pic-item__actions a {
  margin: 0.5rem;
}

.map-container {
  height: 15rem;
  width: 100%;
}

@media (min-width: 768px) {
  .pic-item__image {
    height: 20rem;
  }
}

.read-more-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #007bff; /* Farbe für den Link-Text */
  text-decoration: none;
  margin-left: 5px; /* Optional: Abstand zum vorherigen Text */
  font-size: 14px; /* Optional: Schriftgröße anpassen */
  margin-top: 0px; /* Füge diesen Wert hinzu */
}

.read-more-button:hover {
  text-decoration: underline;
}

.pic-item__image {
  cursor: pointer;
  display: flex;
  justify-content: center; /* Horizontal zentrieren */
  align-items: center; /* Vertikal zentrieren */
  padding: 0 10px; /* Hinzufügen eines transparenten Randes links und rechts */
}

/* Stil für das Bild in der ursprünglichen Ansicht */
.pic-item__image {
  cursor: pointer; /* Ändert den Mauszeiger, um anzuzeigen, dass es anklickbar ist */
}

.pic-item__image img {
  max-width: 100%;
  max-height: 100%;
}

/* Stil für das Bild im Modal */
.pic-item__image-modal-content {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Hintergrund mit 50% Transparenz */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  cursor: pointer; /* Zeige den Mauszeiger (Pointer) über dem Modal */
}

/* Stil für das Bild im Modal */
.pic-item__full-screen-image-container {
  cursor: pointer; /* Zeige den Mauszeiger (Pointer) über dem Bild */
  display: flex; /* Damit das Bild im Container die volle Höhe einnehmen kann */
  justify-content: center; /* Zentrieren des Bilds vertikal */
  align-items: center; /* Zentrieren des Bilds horizontal */
  height: 100vh; /* 100% der verfügbaren Bildschirmhöhe */
}

.pic-item__full-screen-image {
  max-width: 100%;
  max-height: 100%;
}
